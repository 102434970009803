import {NgStyle} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SidebarModule} from 'primeng/sidebar';
import {Subscription, tap} from 'rxjs';
import {AutoSubscribe} from 'src/app/decorators/auto-subscribe.decorator';
import {AutoUnsubscribe} from 'src/app/decorators/auto-unsubscribe.decorator';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {UserDto} from 'src/app/interfaces/backend/dto/user-dto.interface';
import {AdditionalElement} from 'src/app/interfaces/core/additional-element.interface';
import {InputsOptions} from 'src/app/interfaces/core/form/inputs-options.interface';
import {LoginFormControl, LoginFormData} from 'src/app/models/forms/login-form.model';
import {
    ProfileManagerFormControl,
    ProfileManagerFormData,
    ProfileManagerFormModel
} from 'src/app/models/forms/profile-manager-form.model';
import {FormUtilsModule} from 'src/app/modules/form-utils/form-utils.module';
import {prepareInputOptionsFromObjects} from 'src/app/utilities/form.util';

const RAD_MODULES = [
    FormUtilsModule
];

const PRIME_NG_MODULES = [
    SidebarModule
];

@Component({
    selector: 'app-profile-manager',
    standalone: true,
    imports: [
        NgStyle,
        TranslateModule,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './profile-manager.component.html'
})
export class ProfileManagerComponent implements OnInit {
    protected additionalElements: AdditionalElement[] = [];
    protected formModel!: ProfileManagerFormModel;
    protected profiles: LoginFormData[] = [
        {
            [LoginFormControl.USERNAME]: 'lekarz+radiologia@edoktor24.pl',
            [LoginFormControl.PASSWORD]: '123456yU'
        },
        {
            [LoginFormControl.USERNAME]: 'manager+radiologia@edoktor24.pl',
            [LoginFormControl.PASSWORD]: '123456yU'
        },
        {
            [LoginFormControl.USERNAME]: 'placowkademo+radiologia@edoktor24.pl',
            [LoginFormControl.PASSWORD]: '123456yU'
        },
        {
            [LoginFormControl.USERNAME]: 'rezydent+radiologia@edoktor24.pl',
            [LoginFormControl.PASSWORD]: '123456yU'
        }
    ];
    protected inputsOptions: InputsOptions = {};
    protected displayProfileManager: boolean = false;

    protected readonly ProfileManagerFormControl = ProfileManagerFormControl;
    protected readonly LoginFormControl = LoginFormControl;
    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Size = Size;

    @AutoUnsubscribe private subscriptions: Subscription = new Subscription();

    constructor(
        protected profileFacade: ProfileFacade
    ) {
    }

    public ngOnInit() {
        const data = this.getCurrentlyLoggedUser(this.profileFacade.userProfileSignal());
        this.formModel = new ProfileManagerFormModel(data);
        this.prepareInputsOptions();
    }

    @AutoSubscribe
    private watchForUserProfile = () => {
        return this.profileFacade.userProfile$.pipe(
            tap((userProfile) => {
                const data = {[ProfileManagerFormControl.PROFILE]: this.getCurrentlyLoggedUser(userProfile)};
                this.formModel.patchValues(data);
            })
        );
    };

    private getCurrentlyLoggedUser(userProfile: UserDto | null) {
        const email = userProfile?.email;
        const found = this.profiles.find(entry => entry[LoginFormControl.USERNAME] === email);
        return found;
    }

    private prepareInputsOptions() {
        this.inputsOptions = {[ProfileManagerFormControl.PROFILE]: prepareInputOptionsFromObjects(this.profiles, {labelKey: LoginFormControl.USERNAME})};
    }

    protected login($event: ProfileManagerFormData) {
        const data = $event[ProfileManagerFormControl.PROFILE];
        if (!data || data[LoginFormControl.USERNAME] === this.profileFacade.userProfileSignal()?.email) {
            this.profileFacade.logout();
            return;
        }

        this.profileFacade.login(data);
    }
}